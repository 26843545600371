@import 'src/shared/styles/variables.scss';

.subscription-container {
  margin: 0.5em 0;

  .subscription-item-container {
    display: grid;
    grid-template-columns: 1em max-content;
    align-items: center;
    padding: 0.5em 0;
    grid-column-gap: 1em;
    &--large {
      @extend .subscription-item-container;
      grid-template-columns: 2em max-content;
    }

    .subscription-icon-wrapper {
      width: 1.5em;
      &--large {
        width: 1.5em;
        margin: auto;
      }
    }

    .subscription-item-text {
      line-height: 1.2em;
      font-size: 0.8em;
      color: #2b2b2b;
    }
  }
  .subscription-item-price-text {
    font-size: 0.9rem;
    color: #2b2b2b;
    margin: 0.5rem 0;
  }
}
