@import 'src/shared/styles/variables.scss';

.footer {
  max-width: $width-max-page;
  background-color: $color-gray-bright;
  font-family: "Helvetica Neue", sans-serif;
  font-size: $font-size-xxx-small;
  padding: 1.5rem 1.5rem 2rem;
  border-top: 0.03125rem solid $color-border-gray-light;
  text-align: center;

  .footer-section {
    margin: 1rem auto;
    max-width: 16rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
