@import 'src/shared/styles/variables.scss';

.grid-item-wrapper {
  .title {
    color: $color-text-primary;
    margin: 1.875rem 0;
  }

  .grid-item {
    display: grid;
    grid-template-columns: 2rem auto;
    grid-column-gap: 1rem;
    margin-bottom: 1rem;

    .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 1.5rem;
      }
    }

    .content-wrapper {
      grid-column: 2 / span 1;
    }
  }
}
